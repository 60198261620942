import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
// import parse from "html-react-parser";
import { View, ActionButton } from "@adobe/react-spectrum";
import styled from "styled-components";
import Img from "gatsby-image";
import { navigate } from "gatsby";
import { PluginContext } from "../contexts/PluginContext";

// import { graphql, useStaticQuery } from "gatsby";
// import Logo from "../../icons/DUSM-SVG";

const CardContainer = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 35px;
  --easing: cubic-bezier(0.83, 0, 0.17, 1);
  /* passed to this component from plugin.justify-content, from CardContainer to here */
  --logo-color: ${props => props.logoColor};
  /* --logo-color: var(--spectrum-global-color-orange-400); */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1); */

  .card-image-wrapper {
    position: relative;
  }
  .card-image {
    opacity: 1;
    transition: all 0.4s var(--easing) 0.2s;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  #image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  #image-overlay-content {
    transition: all 0.5s var(--easing) 0.1s;
  }
  .image-overlay-transparency {
    fill-opacity: 0.5;
  }

  .logo {
    width: 70px;
    height: 70px;
    transition: all 0.5s ease-in-out;
  }
  .logo-frame {
    opacity: 1;
    transition: all 0.4s var(--easing);
  }
  .logo-frame-fill {
    fill: var(--spectrum-global-color-gray-75);
  }
  .logo-color,
  .logo-color-transparency,
  .logo-frame {
    fill: var(--logo-color);
  }
  .logo-color-transparency {
    fill-opacity: 0.3;
  }

  .card-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s var(--easing);
    width: 100%;
    /* For hover */
    transform: scale(1.2);
    transform: translateY(-50px);
  }

  h1.plugin-title {
    color: var(--spectrum-global-color-gray-800);
    font-size: var(--spectrum-alias-heading-m-text-size);
    font-weight: var(--spectrum-global-font-weight-regular);
    margin: 0;
  }
  h2.plugin-description {
    margin: 0;
    color: var(--spectrum-global-color-gray-500);
    font-size: var(--spectrum-alias-heading-xs-text-size);
    font-weight: var(--spectrum-global-font-weight-regular);
    line-height: 20px;
    text-align: center;
  }
  h3.plugin-version {
    color: var(--spectrum-global-color-gray-300);
    font-weight: var(--spectrum-global-font-weight-bold);
  }

  .cta-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
    width: 80%;
    transform: translateY(0);
    transition: transform 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0.1s;
  }

  .view-container {
    box-sizing: border-box;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
      0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
      0 16px 16px rgba(0, 0, 0, 0.1);
    background-color: var(--spectrum-global-color-gray-75);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    overflow: hidden;
    /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
  }

  @media (hover: hover) {
    .card-image {
      opacity: 0.5;
    }
    #image-overlay {
      opacity: 0.5;
    }
    .logo-frame {
      opacity: 0;
    }
    .card-content-wrapper {
      transform: translateY(0);
    }
    .cta-wrapper {
      /* transition: all 0.4s var(--easing) 0.2s; */
      transform: translateY(50px);
      opacity: 0;
    }

    .view-container {
      overflow: hidden;
      transform: scale(1);
      &:hover {
        transform: scale(1.2);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1),
          0 16px 32px rgba(0, 0, 0, 0.1);
        .logo-frame {
          opacity: 1;
        }
        .logo {
          margin-top: 5px;
          margin-bottom: 15px;
        }
        .card-image {
          opacity: 1;
        }
        #image-overlay-content {
          transform: translateY(-100%);
          opacity: 0;
        }
        .card-content-wrapper {
          transform: translateY(-50px);
        }
        .cta-wrapper {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
`;

const PluginCard = ({ data }) => {
  const { setActivePlugin } = useContext(PluginContext);
  const handleViewClick = () => {
    setActivePlugin(data.slug);
    navigate(`/plugins/${data.slug}`);
  };
  // eslint-disable-next-line global-require, import/no-dynamic-require
  const { Logo } = require(`../icons/plugins/${data.logoSVG}`);
  if (data.slug === "double-usm") {
    // console.log("double-usm", data.heading.fixed);
  }
  return (
    <CardContainer logoColor={data.logoColor}>
      <View
        borderRadius="medium"
        borderTopStartRadius="medium"
        borderTopEndRadius="medium"
        borderBottomEndRadius="small"
        borderBottomStartRadius="small"
        backgroundColor="gray-75"
        height="300px"
        width="200px"
        UNSAFE_className="view-container"
      >
        <div style={{ flexDirection: `column`, alignItems: `center` }}>
          <div className="card-image-wrapper">
            <Img fixed={data.heading.fixed} className="card-image" />
            <svg id="image-overlay" viewBox="0 0 200 113">
              <clipPath id="clipper">
                <path d="M8,0 h184 a8,8 0 0 1 8,8 v105 h-200 v-105 a8,8 0 0 1 8,-8 z" />
              </clipPath>
              <path
                className="logo-color image-overlay-transparency"
                id="image-overlay-content"
                d="M8,0 h184 a8,8 0 0 1 8,8 v105 h-200 v-105 a8,8 0 0 1 8,-8 z"
                clipPath="url(#myClip)"
              />
            </svg>
          </div>
          <div className="card-content-wrapper">
            <Logo />
            <h1 className="plugin-title">{data.title}</h1>
            <h2 className="plugin-description">
              {ReactHtmlParser(data.description)}
            </h2>
            <div className="cta-wrapper">
              <h3 className="plugin-version">{data.version}</h3>
              <ActionButton onPress={handleViewClick}>View</ActionButton>
            </div>
          </div>
        </div>
      </View>
    </CardContainer>
  );
};
export default PluginCard;

// ==============================================
// CARDS https://freefrontend.com/css-cards/
// ==============================================

// flipping card:
// https://codepen.io/alphardex/pen/ExaZgxp

// nice card:
// https://codepen.io/FlorinPop17/pen/dyPvNKK

// Flip effect
// https://codepen.io/faelplg/pen/MWwxred

// Dynamic effect
// https://codepen.io/andrewhawkes/pen/RwwOJrO

// Simpler over effect
// https://codepen.io/that_boy_curt/pen/mdbvxoW?editors=1100

// Hover animation
// https://codepen.io/dyarfi/pen/QWbbmaG

// Hover and pop up text
// https://codepen.io/hexagoncircle/pen/XWbWKwL

// BW color on hover
// https://codepen.io/Jhonierpc/pen/MWgBJpy

// Image Zoom and text popup effect
// https://codepen.io/tgaychey/pen/PROMVy

// Easing functions
// https://easings.net/

// Favicon
// https://evilmartians.com/chronicles/how-to-favicon-in-2021-six-files-that-fit-most-needs
