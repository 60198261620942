import React, { useEffect, useContext } from "react";
import { Flex } from "@adobe/react-spectrum";
// import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import PluginCard from "../components/PluginCard";
import { TabsContext } from "../contexts/TabsContext";
import PluginJSON from "../data/plugins.json";
import PluginsLayout from "../components/PluginsLayout";
import { PluginContext } from "../contexts/PluginContext";

// const MainContainerStyled = styled.div`
//   @media only screen and (min-width: 600px) {
//   }
//   @media only screen and (min-width: 900px) {
//   }
//   @media only screen and (min-width: 1200px) {
//   }
//   @media only screen and (min-width: 1800px) {
//   }
// `;

const PluginsPage = () => {
  // getting all the images via GraphQL
  const allImages = useStaticQuery(
    graphql`
      query MyQuery {
        allFile(filter: { relativeDirectory: { eq: "highlights" } }) {
          nodes {
            name
            childImageSharp {
              fixed(width: 200, quality: 80) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    `
  );

  // Merging with JSON data:
  // console.log("allImages", JSON.stringify(allImages));
  // console.log("PluginJSON", PluginJSON);
  // I'm filling the "heading" prop with the childImageSharp based on a match with
  // the "headingName"
  PluginJSON.forEach(plugin => {
    // eslint-disable-next-line no-param-reassign
    plugin.heading = allImages.allFile.nodes.filter(
      node => node.name === plugin.headingName
    )[0].childImageSharp;
  });

  // console.log("PluginJSON.length", PluginJSON.length);
  const { activeTab, setActiveTab } = useContext(TabsContext);
  const { activePlugin, setActivePlugin } = useContext(PluginContext);

  useEffect(() => {
    if (activeTab !== "plugins") {
      setActiveTab("plugins");
    }
    if (activePlugin !== "allPlugins") setActivePlugin("allPlugins");
  });

  return (
    <PluginsLayout>
      <SEO title="Photoshop Plugins" slug="plugins" />
      <Flex
        direction="row"
        justifyContent="center"
        gap="size-200"
        wrap
        UNSAFE_className="plugins-container"
      >
        {PluginJSON.map(data => (
          <PluginCard data={data} key={data.title} />
        ))}
      </Flex>
    </PluginsLayout>
  );
};

export default PluginsPage;
